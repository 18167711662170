import React from 'react';
import ProductCard from './ProductCard';
import image1 from './../../Media/image1.jpeg';
import image2 from './../../Media/image2.jpeg';
import image3 from './../../Media/image3.jpeg';
import AOS from 'aos';
import 'aos/dist/aos.css'

AOS.init({
  delay: 400, 
  duration: 300, 
  easing: 'ease',
  once: false, 
  mirror: true,
});

const products = [
  {
    imgSrc: image1,
    title: 'Silt Fence',
    description: 'For applications in construction industries, paved/unpaved roadways, haul roads for logging/landfills, temporary construction entrances, etc.',
    features: [
      'NTPEP Certified.',
      'Customizable color solutions manufactured on circular & sulzer looms.',
      'Guarantee no fraying.',
      'Available in slit tape & mono-filament.',
      'Width Available: 24”, 36”, 42” & 48 “.',
      'Length: customizable as per customer request.',
      'Offer 500 – 1000 hrs of UV enduring performance'
    ],
    category: 'TabOne'
  },
  {
    imgSrc: image2,
    title: 'Album 2',
    description: 'something',
    features: [
      'This is Product 2 - Feature 1',
      'This is Product 2 - Feature 2',
      'This is Product 2 - Feature 3'
    ],
    category: 'TabTwo'
  },
  {
    imgSrc: image3,
    title: 'Album 3',
    description: 'something',
    features: [
      'This is Product 3 - Feature 1',
      'This is Product 3 - Feature 2',
      'This is Product 3 - Feature 3'
    ],
    category: 'TabThree'
  },
];

const ProductCards = ({ category }) => {
  const filteredProducts = category === 'All' ? products : products.filter(product => product.category === category);

  return (
    <div className="flex flex-wrap justify-center mx-auto lg:p-20" data-aos="zoom-in">
      {filteredProducts.map((product, index) => (
        <ProductCard
          key={index}
          imgSrc={product.imgSrc}
          title={product.title}
          features = {product.features}
          description={product.description}
          category={product.category}
        />
      ))}
    </div>
  );
};

export default ProductCards;

