import React from 'react'

const Coming = () => {
  return (
    <>
    <div className="title text-2xl lg:text-6xl md-auto pt-10 lg:pt-20 flex justify-center">
            <h1>We'll be up soon</h1>
    </div>
    <div className="title text-xl lg:text-4xl md-auto pt-10 lg:pt-20 flex justify-center">
        <h2>Thank you for your patiance 🤝</h2>
    </div>
    </>
  )
}

export default Coming