import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from './Comps/HomePage';
import ProductsPage from './Comps/ProductsPage';
import TeamPage from './Comps/TeamPage';
import ChatPage from './Comps/ChatPage';
import Admin from './Comps/admin/Admin';
import AddProduct from './Comps/admin/AddProduct';
import AddNews from './Comps/admin/AddNews';
import './index.css'
import NavBar from './Comps/GlobalComps/NavBar';
function App() {
  return (
      <Router>
        <div>
        <NavBar/>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/products" element={<ProductsPage />} />
            <Route path="/team" element={<TeamPage />} />
            <Route path="/chat" element={<ChatPage />} />
            <Route path="/admin" element={<Admin />} />
            <Route path="/addproduct" element={<AddProduct />} />
            <Route path="/addnews" element={<AddNews />} />
          </Routes>
        </div>
      </Router>
     
  );
}

export default App;
