import React from 'react';
import NavBar from './GlobalComps/NavBar';
import './../index.css';
import Carosoul from './HomeComps/Carosoul';
import HomeCards from './HomeComps/HomeCards';
import Contact from './HomeComps/Contact';
import Footer from './GlobalComps/Footer';
import Values from './HomeComps/Values';
import AOS from 'aos';
import 'aos/dist/aos.css'

AOS.init({
  delay: 400, 
  duration: 300, 
  easing: 'ease',
});
const HomePage = () => {
  
  return (
    <>
      <div className="navBar">
        {/* <NavBar /> */}
      </div>
      <div className="HomePage flex flex-col">
        <div className="carosoul flex-grow" style={{ minHeight: '100vh' }}>
          <Carosoul />
        </div>
        <div data-aos="zoom-in" className="cards flex-grow" style={{ minHeight: '100vh' }}>
          <HomeCards />
        </div>
        <div  data-aos="zoom-in" className="values flex-grow" style={{ minHeight: '100vh' }}>
          <Values />
        </div>
        <div data-aos="zoom-in" className="contact flex-grow" style={{ minHeight: '100vh' }}>
          <Contact />
        </div>
        <div className="footer">
          <Footer />
        </div>
      </div>
    </>
  );
}

export default HomePage;
