import React, { useState } from 'react';
import NavBar from './GlobalComps/NavBar';
import TabPage from './ProductsComps/TabPage';
import Footer from './GlobalComps/Footer';
import ProductCards from './ProductsComps/ProductCards';
import AOS from 'aos';
import 'aos/dist/aos.css'

AOS.init({
  delay: 400, 
  duration: 300, 
  easing: 'ease',
});

const ProductsPage = () => {
  const [activeTab, setActiveTab] = useState('All');

  return (
    <>
      {/* <NavBar /> */}
      <div className='Content'>
      <div className="title text-6xl md-auto pt-10 lg:pt-20 flex justify-center">
        <h1>Products</h1>
      </div>
      <div className="Tabs">
        <TabPage activeTab={activeTab} setActiveTab={setActiveTab} />
      </div>
      <div data-aos="zoom-in"className="Cards">
        <ProductCards category={activeTab} />
      </div>
      </div>
      <Footer className=""/>
    </>
  );
};

export default ProductsPage;
